import React from "react";
import { AboutOne, AboutTwo, AboutThree, Testimonials, Layout } from "../components";
import styled from "styled-components";
import Helmet from "react-helmet";

export default () => {
    return (
        <>
            <Helmet>
                <title>About - Braden Chamberlain - Security Home Mortgage</title>
                <meta
                    name="description"
                    content="I'm Braden Chamberlain; a loan officer specializing in Utah home loans and refinancing."
                />
                <meta property="og:image:secure_url" content="https://mortgagesbybraden.netlify.app/ogpImage.jpg" />
                <meta property="og:image" content="http://mortgagesbybraden.netlify.app/ogpImage.jpg" />
                <meta property="og:title" content="About - Braden Chamberlain - Security Home Mortgage" />
                <meta
                    property="og:description"
                    content="I'm Braden Chamberlain; a loan officer specializing in Utah home loans and refinancing."
                />
                <meta property="og:url" content="https://mortgagesbybraden.netlify.app/" />
            </Helmet>
            <Layout>
                <StyledSection id="about">
                    <h2>About</h2>
                    <Wrapper>
                        <AboutOne />
                        <AboutTwo />
                        <AboutThree />
                        <Testimonials />
                    </Wrapper>
                </StyledSection>
            </Layout>
        </>
    );
};

const StyledSection = styled.section`
    padding: 0 1rem;

    h2 {
        text-align: center;
        font-size: 4rem;
    }
`;

const Wrapper = styled.div`
    max-width: 1500px;
    margin: 0 auto;
`;
